@import "variables";

@import url('https://fonts.googleapis.com/css2?family=Gantari:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Gantari", sans-serif;
  font-optical-sizing: auto;
}

.login-pf {
  body {
    background-size: cover;
    background: url(../img/login-background-lukla.webp) no-repeat center center fixed;
  }

  .card-pf {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(15px);
    border-color: $violet-principal;
    border-radius: 22px;

    #kc-page-title, #kc-current-locale-link, label {
      color: #333;
    }

    input {
      &:hover {
        border-color: $violet-principal;
      }
    }

    #kc-form-buttons {
      display: flex;
      justify-content: center;
    }

    a {
      color: $violet-principal;
    }

    #kc-login {
      background: linear-gradient(90deg, $violet-degrade-1 0%, $violet-degrade-2 100%);
      width: 250px;
      border-radius: 20px;
      height: 40px;
      transition: background-color 150ms;

      &:hover {
        background: linear-gradient(90deg, $rose-degrade-1 0%, $rose-degrade-2 100%);
      }

      &:active {
        color: #fff;
      }
    }
  }

  @media (max-width: 767px) {
    #kc-header-wrapper {
      padding: 20px 5px;

      div.kc-logo-text {
        margin: auto;
      }
    }
  }

  #social-oidc {
    background: linear-gradient(90deg, $violet-degrade-1 0%, $violet-degrade-2 100%);
    width: 250px;
    border-radius: 20px;
    height: 40px;
    color: #fff;
    font-size: 14px;

    &:hover {
      background: linear-gradient(90deg, $rose-degrade-1 0%, $rose-degrade-2 100%);
    }

    &:after {
      border: 0;
    }
  }

  #logo {
    max-height: 70px;
  }
}
